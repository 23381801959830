import * as React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image"
import { Layout } from "../components/layout";
import styled from 'styled-components';

const AboutPage = ({ data }) => {

  return (
    <Layout>

        <Title>About Tomsnaps</Title>
        <Intro>Find out about Tom.</Intro>

        <Wrapper>  
          <MainText>
            <p>
            Hi I'm Tom, I live in Brighton near the English seaside and Sussex downs with my lovely Wife. I'm a software engineer by day and in my spare time, I love to get out and about taking photos of the wonderful world around me.
            </p>
            <p>
            For me photography is a hobby which I love, going on adventures near and far snapping some photos so others can see the world through my lens.
            I primarily take photos using my Sony A6000 camera or my iPhone 11.
            </p>
            <p>
              I also founded <a href="https://www.snaphints.com" target="_blank"  rel="noreferrer">snaphints.com</a> in early 2020, a photography tips and hints website which I hope to grow into a photography community.
            </p>
          </MainText>

          <StyledImg fluid={data.photo.childImageSharp.fluid} alt="Tom Howard" />
        </Wrapper>  

    </Layout>
  )
}



const Title = styled.h1`
  font-size: var(--text-display);
  font-weight: var(--bold);
  margin: 0;
  padding: var(--space-2xl) var(--size-gutter-raw) var(--space-2xl);
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 800px) {
    flex-direction: row;
  }
`

const Intro = styled.p`
  max-width: 800px;
  padding: 0 var(--size-gutter-raw) var(--space-2xl);
`

const MainText = styled.div`
  width: 100%;
  padding: 0 var(--size-gutter-raw) var(--space-2xl);

  @media(min-width: 800px) {
    width: 50%;
  }

  p {
    margin-bottom: 10px;
    font-size: 18px;
  }
`

const StyledImg = styled(Img)`
  width: 100%;

  @media(min-width: 800px) {
    width: 50%;
  }

  img {
    padding: 0 var(--size-gutter-raw) var(--space-2xl);
  }
`

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    photo: file(
      relativePath: { eq: "tom-howard.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage data={data} {...props} />
    )}
  />
)